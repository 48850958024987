var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //
          'id',
          'subject',
          //helpers.createdAt,
          { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 0; display: none;' }
        ],"config":{
          name: 'communication',
          url: 'communications',
          //route: '/communications/sending_communications',
          params: Object.assign({}, {building_id: this.building_id || 0}, (_vm.params || {})),
          //duplicate: true,
          //ignore_isDuplicable: true,
          noCreate: true,
          noOptions: true,
          parseItems: _vm.parseItems
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }