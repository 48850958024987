<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //
            'id',
            'subject',
            //helpers.createdAt,
            { key: 'options', filter: false, _classes: 'text-center', _style: 'width: 0; display: none;' }
          ]"
          :config="{
            name: 'communication',
            url: 'communications',
            //route: '/communications/sending_communications',
            params: { building_id: this.building_id || 0, ...(params || {}) },
            //duplicate: true,
            //ignore_isDuplicable: true,
            noCreate: true,
            noOptions: true,
            parseItems
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'NU-Communications',
  mixins: [crudMixin],
  props: ['building_id', 'fields', 'filters', 'config', 'params'],
  watch: {
    building_id(new_value, old_value) {
      if (new_value && new_value !== old_value) this.onGetData(new_value)
    }
  },
  methods: {
    parseItems(parent, items) {
      return items.map(item => {
        item.is_principal = !!item.communication_requests.filter(cr => cr.status !== 'pending' || (cr.status === 'pending' && !cr.send_at)).length
        return item
      })
    },

    onGetData(building_id) {
      // if (month_ids) {
      // this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { building_id }, true)
      // }
    },
    onDataChanged() {
      this.checked = this.$refs.crud_table.getChecked()
    }
  }
}
</script>
